import { Routes } from '@angular/router';

import { ACCOUNT_ROUTE_LIST } from '@proj-customer/app/modules/account/account.routes';
import { CUSTOMER_ROUTE_LIST } from '@lib-shared/modules/customer';
import { AUTH_INTEGRATION_ROUTE_LIST } from '@lib-auth/auth';

export const APP_ROUTES: Routes = [
	...AUTH_INTEGRATION_ROUTE_LIST,
	...CUSTOMER_ROUTE_LIST,
	...ACCOUNT_ROUTE_LIST,
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'minha-conta',
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: 'minha-conta',
	},
];
