import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { effect, Component, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { CpfCnpjPipe } from '@lib-core/pipes';
import { CustomerModel, RepresentativeModel } from '@lib-core/models';
import { AppModeStore, CustomerStore, RepresentativeStore, SalesTeamStore } from '@lib-core/stores';
import { AppLoaderService, CustomerService, ToastService, GalleryService, RouterService } from '@lib-core/services';
import { getDataTableOptions, getDataTableOrderByIndex, getNotOrderableColumns } from '@lib-core/config';

@Component({
	standalone: true,
	selector: 'shared-customer-select-modal',
	imports: [CommonModule, CpfCnpjPipe, NgbModalModule, DataTablesModule],
	templateUrl: './customer-select-modal.component.html',
})
export class CustomerSelectModalComponent {
	customerList: CustomerModel[];
	representative: RepresentativeModel;

	dtOptions = {
		...getDataTableOptions(),
		...getNotOrderableColumns([3]),
		...getDataTableOrderByIndex(1),
		lengthChange: false,
	};

	@ViewChild('noSalesTeamModalError')
	private noSalesTeamModalError: ElementRef;

	@Output()
	customerSelected = new EventEmitter<CustomerModel>();

	constructor(
		private modalService: NgbModal,
		private appModeStore: AppModeStore,
		private toastService: ToastService,
		private customerStore: CustomerStore,
		private appLoaderService: AppLoaderService,
		private galleryService: GalleryService,
		private salesTeamStore: SalesTeamStore,
		private customerService: CustomerService,
		private representativeStore: RepresentativeStore,
	) {
		effect(() => {
			this.representative = this.representativeStore.get();
			this.queryCustomers();
		});
	}

	queryCustomers(): void {
		if (!this.representative?.code) {
			return;
		}
		this.appLoaderService.start();

		this.customerService.listCustomersByRepresentative(this.representative.code).subscribe({
			next: response => {
				this.customerList = null;

				if (response?.success) {
					this.customerList = response.data;
					return;
				}
				this.toastService.danger('Erro ao buscar clientes: ' + response?.message);
			},
			error: () => {
				this.toastService.danger('Erro ao buscar clientes, por favor tente novamente.');
				this.modalService.dismissAll();
			},
			complete: () => {
				setTimeout(() => {
					this.appLoaderService.stop();
				}, 500);
			},
		});
	}

	dismiss(): void {
		this.modalService.dismissAll();
	}

	select(customer: CustomerModel): void {
		if (this.appModeStore.isPortalCustomer()) {
			this.onCustomerSelectSuccess(customer);
			return;
		}

		this.galleryService.listSalesTeam(customer.code).subscribe({
			next: response => {
				if (!response.success || !response.data || response.data.length === 0) {
					this.showNoSalesTeamErrorModal();
					return;
				}
				this.salesTeamStore.setCustomerSalesTeamList(response.data);
				this.onCustomerSelectSuccess(customer);
			},
			error: () => {
				this.showNoSalesTeamErrorModal();
			},
		});
	}

	onCustomerSelectSuccess(customer: CustomerModel) {
		this.customerStore.set(customer);
		this.customerSelected.emit(customer);
		this.modalService.dismissAll();
	}

	showNoSalesTeamErrorModal(): void {
		this.modalService.open(this.noSalesTeamModalError, { backdrop: 'static' }).result.then(
			() => {},
			() => {},
		);
	}
}
