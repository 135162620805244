<shared-navbar [enableCart]="false" [enableSidebar]="false" [enableMyAccount]="false" />
<div class="container-xxl mt-4">
	<div class="row">
		<nav class="col-24 col-lg-8 col-xl-5 col-xxl-4 mb-4">
			<shared-account-menu [routeList]="accountRoutes" />
		</nav>
		<main class="col-24 col-lg-16 col-xl-19 col-xxl-20">
			<router-outlet />
		</main>
	</div>
</div>
