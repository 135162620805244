import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { RouterLink } from '@angular/router';

import { ACCOUNT_RETURN_ROUTE_NAMES } from '@lib-shared/modules/account/account.constants';

import { CustomerStore } from '@lib-core/stores';
import { DateShortPipe } from '@lib-core/pipes';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SalesOrderService } from '@lib-core/services';
import { PageTitleComponent } from '@lib-shared/components';
import { CustomerModel, SalesReturnModel } from '@lib-core/models';
import { getDataTableOptions, getDataTableOrderByIndex } from '@lib-core/config';
import { SalesReturnType, SALES_RETURN_STEP_DESCRIPTION } from '@lib-core/enums';

@Component({
	standalone: true,
	selector: 'shared-account-sales-return-up-to-five',
	imports: [RouterLink, CommonModule, PageTitleComponent, DateShortPipe, DataTablesModule, NgbTooltipModule],
	templateUrl: './account-sales-return-up-to-five.component.html',
})
export class AccountSalesReturnUpToFiveComponent {
	customer: CustomerModel;
	dtOptions = { ...getDataTableOptions(), ...getDataTableOrderByIndex(0, 'desc') };

	salesReturnList: SalesReturnModel[];
	accountReturnRoutes = ACCOUNT_RETURN_ROUTE_NAMES;

	constructor(private customerStore: CustomerStore, private salesOrderService: SalesOrderService) {
		this.customer = this.customerStore.get();
	}

	ngOnInit() {
		if (this.customer) {
			this.salesOrderService.lisReturnOrders(this.customer.cnpj, SalesReturnType.UpToFive).subscribe(response => {
				this.salesReturnList = response ?? [];
			});
		}
	}

	get isLoaded(): boolean {
		return this.salesReturnList !== undefined;
	}

	get hasReturns(): boolean {
		return this.salesReturnList?.length > 0;
	}

	shouldShowAction(salesReturn: SalesReturnModel): boolean {
		return ![SALES_RETURN_STEP_DESCRIPTION.Fiscal, SALES_RETURN_STEP_DESCRIPTION.Processing].includes(salesReturn.nomeEtapa);
	}

	getActionIcon(salesReturn: SalesReturnModel): string {
		if (salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.ProofOfDiscard) {
			return 'pencil';
		}
		return 'search';
	}

	getActionLabel(salesReturn: SalesReturnModel): string {
		if (salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.ProofOfDiscard) {
			return 'Editar';
		}
		return 'Detalhar';
	}

	getActionClass(salesReturn: SalesReturnModel): string {
		if (salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.ProofOfDiscard) {
			return 'success';
		}
		return 'primary';
	}

	getTooltip(salesReturn: SalesReturnModel): string {
		return this.shouldShowAction(salesReturn) ? '' : 'Não há ações para você executar nessa etapa, por favor aguarde';
	}
}
