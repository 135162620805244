import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';

import { CustomerStore } from '@lib-core/stores';
import { DateShortPipe } from '@lib-core/pipes';
import { SALES_RETURN_STEP_DESCRIPTION, SalesReturnType } from '@lib-core/enums';
import { SalesOrderService } from '@lib-core/services';
import { PageTitleComponent } from '@lib-shared/components';
import { getDataTableOptions } from '@lib-core/config';
import { CustomerModel, SalesReturnModel } from '@lib-core/models';
import { ACCOUNT_RETURN_ROUTE_NAMES } from '@lib-shared/modules/account/account.constants';

@Component({
	standalone: true,
	selector: 'shared-account-sales-return-more-than-six',
	imports: [CommonModule, PageTitleComponent, DateShortPipe, DataTablesModule, NgbTooltipModule, RouterLink],
	templateUrl: './account-sales-return-more-than-six.component.html',
})
export class AccountSalesReturnMoreThanSixComponent {
	customer: CustomerModel;
	dtOptions = getDataTableOptions();
	salesReturnList: SalesReturnModel[];
	accountReturnRoutes = ACCOUNT_RETURN_ROUTE_NAMES;

	constructor(private router: Router, private customerStore: CustomerStore, private salesOrderService: SalesOrderService) {
		this.customer = this.customerStore.get();
	}

	ngOnInit() {
		if (this.customer) {
			this.salesOrderService.lisReturnOrders(this.customer.cnpj, SalesReturnType.SixOrMore).subscribe(response => {
				this.salesReturnList = response ?? [];
			});
		}
	}

	get isLoaded(): boolean {
		return this.salesReturnList !== undefined;
	}

	isButtonEnableForAction(salesReturn: SalesReturnModel): boolean {
		return (
			salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.Request ||
			salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.Done ||
			salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.goodsReturn
		);
	}

	get hasReturns(): boolean {
		return this.salesReturnList?.length > 0;
	}

	createReturn() {
		this.router.navigate([this.accountReturnRoutes.sixOrMore.new.url]);
	}

	editReturn(id: number) {
		this.router.navigate([this.accountReturnRoutes.sixOrMore.edit.url, id]);
	}

	getTooltip(salesReturn: SalesReturnModel): string {
		return this.shouldShowAction(salesReturn) ? '' : 'Não há ações para você executar nessa etapa, por favor aguarde';
	}

	shouldShowAction(salesReturn: SalesReturnModel): boolean {
		return this.isButtonEnableForAction(salesReturn);
	}

	getActionClass(salesReturn: SalesReturnModel): string {
		if (salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.goodsReturn) {
			return 'success';
		}
		return 'primary';
	}

	getActionIcon(salesReturn: SalesReturnModel): string {
		if (this.shouldShowAction(salesReturn)) {
			if (salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.goodsReturn) {
				return 'box-seam';
			}
			return 'search';
		}
		return 'clipboard2-check';
	}

	getActionLabel(salesReturn: SalesReturnModel): string {
		if (this.shouldShowAction(salesReturn)) {
			if (salesReturn.nomeEtapa == SALES_RETURN_STEP_DESCRIPTION.goodsReturn) {
				return 'Enviar';
			}
			return 'Detalhar';
		}
		return 'Aguarde';
	}
}
