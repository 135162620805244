import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { SalesReturnService } from '@lib-core/services';
import { PageTitleComponent } from '@lib-shared/components';
import { SalesReturnStepType, SalesReturnType } from '@lib-core/enums';
import { AccountSalesReturnFormComponent } from '@lib-shared/modules/account/account-sales-return/components/account-sales-return-form/account-sales-return-form.component';

@Component({
	standalone: true,
	selector: 'shared-account-sales-return-new',
	imports: [CommonModule, NgbNavModule, AccountSalesReturnFormComponent, PageTitleComponent],
	templateUrl: './account-sales-return-new.component.html',
})
export class AccountSalesReturnNewComponent {
	fluxo: SalesReturnType;
	etapa: SalesReturnStepType;

	constructor(private activatedRoute: ActivatedRoute, private salesReturnService: SalesReturnService) {
		this.fluxo = this.activatedRoute.snapshot.data?.fluxo as SalesReturnType;
		this.etapa = this.salesReturnService.getRequestStep(this.fluxo);
	}
}
