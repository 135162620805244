import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbDateAdapter, NgbDatepickerI18n, NgbDatepickerModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { SalesReturnService } from '@lib-core/services';
import { fileToBase64, generateGuid } from '@lib-core/helpers';
import { SalesReturnFieldIdType, SalesReturnFieldType } from '@lib-core/enums';
import { CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '@lib-core/providers';
import { SalesReturnProcessoAnexoModel, SalesReturnProcessoListaItemModel, SalesReturnProcessoListaModel, SalesReturnProcessoModel } from '@lib-core/models';

@Component({
	standalone: true,
	selector: 'shared-account-sales-return-invoices',
	imports: [FormsModule, CommonModule, ReactiveFormsModule, NgbDatepickerModule],
	providers: [
		I18n,
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
	templateUrl: './account-sales-return-invoices.component.html',
})
export class AccountSalesReturnInvoicesComponent {
	uuid: string;
	listaId: number;
	submitted = false;
	invoiceForm!: FormGroup;
	removedAttachment: string;
	invoice: SalesReturnProcessoListaModel;
	SalesReturnFieldIdType = SalesReturnFieldIdType;

	@Input()
	invoiceList: SalesReturnProcessoListaModel[];

	@Input()
	attachmentList: SalesReturnProcessoAnexoModel[];

	@Input()
	fluxo: number;

	@Input()
	etapa: number;

	@Input()
	isActive: boolean;

	@Output()
	invoiceAdded = new EventEmitter<SalesReturnProcessoModel>();

	@Output()
	invoiceEdited = new EventEmitter<SalesReturnProcessoModel>();

	@Output()
	invoiceRemoved = new EventEmitter<{ uuid: string; attachments: SalesReturnProcessoAnexoModel[] }>();

	@ViewChild('invoiceModal')
	invoiceModal: ElementRef;

	@ViewChild('maxItemCount')
	maxItemCount: ElementRef;

	constructor(private fb: FormBuilder, private modalService: NgbModal, private salesReturnService: SalesReturnService) {}

	ngOnInit(): void {
		this.listaId = this.salesReturnService.getInvoiceListId(this.fluxo);
		this.registerForm();
	}

	get f() {
		return this.invoiceForm.controls;
	}

	get isInactive(): boolean {
		return !this.isActive;
	}

	get hasInvoices(): boolean {
		return this.invoiceList?.length > 0;
	}

	get isReadonly(): boolean {
		return this.isProofOfDiscard;
	}

	get isRequest(): boolean {
		return this.salesReturnService.isRequestStep(this.etapa, this.fluxo);
	}

	get isProofOfDiscard(): boolean {
		return this.salesReturnService.isProofOfDiscardStep(this.etapa, this.fluxo);
	}

	get isAddOrRemoveEnable(): boolean {
		return this.isActive && this.isRequest;
	}

	registerForm() {
		const numberPatternValidator = Validators.pattern('^[0-9]*$');

		this.invoiceForm = this.fb.group({
			number: ['', [Validators.required, numberPatternValidator]],
			serie: ['', [Validators.required, numberPatternValidator]],
			issueDate: ['', [Validators.required]],
			attachment: [null, [Validators.required]],
		});
	}

	setUuid(): void {
		this.uuid = this.invoice ? this.invoice.lista[0][0].uuid : generateGuid();
	}

	onAddAttachment(event: any) {
		if (this.isInactive || !event.target?.files[0]!) {
			return;
		}

		const file = event.target.files[0];

		fileToBase64(event.target).subscribe(base64 => {
			this.invoiceForm.patchValue({
				attachment: {
					nome: file.name,
					parent: this.uuid,
					base64: `data:${file.type};base64,${base64}`,
					replaceFrom: this.getAttachment()?.nome,
				},
			});

			this.invoiceForm.markAsDirty();
			event.target.value = null;
		});
	}

	removeAttachment() {
		this.removedAttachment = this.invoiceForm.value.attachment?.nome;
		this.invoiceForm.patchValue({ attachment: null });
		this.invoiceForm.markAsDirty();
	}

	getItem(type: SalesReturnFieldIdType) {
		if (this.invoice && this.invoice.lista?.length > 0) {
			return this.invoice.lista[0].find(i => i.id === type);
		}
		return null;
	}

	getAttachmentBase64(filename: string) {
		if (!filename || filename === '') {
			return '';
		}
		return this.attachmentList.find(a => a.nome === filename)?.base64 ?? '';
	}

	getAttachment() {
		const value = this.getItem(SalesReturnFieldIdType.InvoiceAttachment);

		if (value) {
			return {
				nome: value?.valor,
				parent: this.uuid,
				base64: this.getAttachmentBase64(value?.valor),
			};
		}

		return null;
	}

	createModel() {
		if (this.isInactive) {
			return null;
		}

		const { number, serie, issueDate, attachment } = this.invoiceForm.value;

		return {
			anexos: this.createAttachmentModel(),
			listas: [
				{
					uuid: this.uuid,
					lista_id: this.listaId,
					fluxo_id: this.fluxo,
					etapa_id: this.etapa,
					lista: [
						[
							this.createProcessoItemModel(number, SalesReturnFieldIdType.InvoiceNumber, SalesReturnFieldType.Number),
							this.createProcessoItemModel(serie, SalesReturnFieldIdType.InvoiceSeries, SalesReturnFieldType.Number),
							this.createProcessoItemModel(issueDate, SalesReturnFieldIdType.InvoiceIssueDate, SalesReturnFieldType.Date),
							this.createProcessoItemModel(attachment.nome, SalesReturnFieldIdType.InvoiceAttachment, SalesReturnFieldType.Attachment),
						],
					],
				} as SalesReturnProcessoListaModel,
			],
		} as SalesReturnProcessoModel;
	}

	createProcessoAnexoModel(file: any): SalesReturnProcessoAnexoModel {
		return {
			nome: file?.nome,
			field: SalesReturnFieldIdType.InvoiceAttachment,
			etapa: this.etapa,
			base64: file?.base64,
			parent: file?.parent,
			replaceFrom: file?.replaceFrom,
		};
	}

	createProcessoItemModel(value: string, field: SalesReturnFieldIdType, fieldType: SalesReturnFieldType): SalesReturnProcessoListaItemModel {
		return {
			id: field,
			uuid: this.uuid,
			tipo: fieldType,
			valor: value ?? '',
			lista_uuid: this.uuid,
		};
	}

	createAttachmentModel(onlyToRemove = false) {
		const anexos = [];

		if (this.removedAttachment) {
			anexos.push(this.createProcessoAnexoModel({ replaceFrom: this.removedAttachment, parent: this.uuid }));
			this.removedAttachment = null;
		}

		if (onlyToRemove) {
			return anexos;
		}

		anexos.push(this.createProcessoAnexoModel(this.invoiceForm.value.attachment));
		return anexos;
	}

	clear() {
		this.invoice = null;
		this.submitted = false;
		this.invoiceForm.reset();
		this.modalService.dismissAll();
	}

	createLabel(item: SalesReturnProcessoListaModel, field: SalesReturnFieldIdType): string {
		const value = item.lista[0].find(i => i.id === field);
		return value.descricao ? `${value.valor} - ${value.descricao}` : value.valor;
	}

	editInvoice(invoice: SalesReturnProcessoListaModel) {
		if (this.isInactive || this.isReadonly) {
			return;
		}

		this.invoice = invoice;
		this.invoice.editing = true;

		this.setUuid();

		this.invoiceForm.patchValue({
			number: this.getItem(SalesReturnFieldIdType.InvoiceNumber)?.valor,
			serie: this.getItem(SalesReturnFieldIdType.InvoiceSeries)?.valor,
			issueDate: this.getItem(SalesReturnFieldIdType.InvoiceIssueDate)?.valor,
			attachment: this.getAttachment(),
		});

		this.addInvoice();
	}

	removeInvoice(invoice: SalesReturnProcessoListaModel) {
		if (this.isInactive || this.isReadonly) {
			return;
		}

		this.invoice = invoice;
		this.setUuid();

		this.removedAttachment = this.getItem(SalesReturnFieldIdType.InvoiceAttachment)?.valor;

		this.invoiceRemoved.emit({
			uuid: this.uuid,
			attachments: this.createAttachmentModel(true),
		});

		this.invoice = null;
	}

	addInvoice() {
		if (this.isInactive) {
			return;
		}

		if (this.invoiceList?.length >= 1) {
			this.modalService.open(this.maxItemCount, { centered: true }).result.then();
			return;
		}

		this.setUuid();

		this.modalService.open(this.invoiceModal, { size: 'lg', backdrop: 'static' }).result.then(
			() => {},
			() => this.clear(),
		);
	}

	saveInvoice() {
		if (this.isInactive) {
			return;
		}

		this.submitted = true;

		if (this.invoiceForm.invalid) {
			return;
		}

		this.submitted = false;

		const processo = this.createModel();

		if (this.invoice) {
			this.invoiceEdited.emit(processo);
			this.clear();
			return;
		}

		this.invoiceAdded.emit(processo);
		this.clear();
	}
}
