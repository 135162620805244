<div class="footer mt-5 w-100 bottom-0 bg-primary" *ngIf="isAuthenticated">
	<div class="container-xxl text-light p-4">
		<div class="row">
			<div class="col-24 col-lg-6 text-center text-lg-start px-3">
				<div class="mb-3">
					<h5>Grupo Malwee</h5>
					<ul class="list-unstyled mb-2">
						<li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="{{customerAddresses.aboutUs}}"
								target="_blank"
								>Sobre</a
							>
						</li>
						<li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="{{customerAddresses.values}}"
								>Nossos Valores</a
							>
						</li>
					</ul>
				</div>
				<h5>Nossas Marcas</h5>
				<div class="d-flex w-100 flex-row mb-2">
					<div class="col-12 d-flex flex-column">
						<a class="text-white" href="{{customerAddresses.brands.malwee}}"
							><img
								class="align-items-center"
								src="../../../../assets/logos/malwee_brand_80px_30px.png"
								alt=""
						/></a>
						<a class="text-white" href="{{customerAddresses.brands.malweeKids}}"
							><img
								class="align-items-center"
								src="../../../../assets/logos/malwee_kids_brand_80px_30px.png"
								alt=""
						/></a>
						<a class="text-white" href="{{customerAddresses.brands.basicamente}}"
							><img
								class="align-items-center"
								src="../../../../assets/logos/baiscamente_brand_80px_30px.png"
								alt=""
						/></a>
					</div>
					<div class="col-12 d-flex flex-column">
						<a class="text-white" href="{{customerAddresses.brands.enfim}}"
							><img
								class="align-items-center"
								src="../../../../assets/logos/enfim_brand_80px_30px.png"
								alt=""
						/></a>
						<a class="text-white" href="{{customerAddresses.brands.carinhoso}}"
							><img
								class="align-items-center"
								src="../../../../assets/logos/carinhoso_brand_80px_30px.png"
								alt=""
						/></a>
						<a class="text-white" href="{{customerAddresses.brands.basico}}"
							><img
								class="align-items-center"
								src="../../../../assets/logos/basico_brand_80px_30px.png"
								alt=""
						/></a>
					</div>
				</div>
			</div>
			<div class="col-24 col-md-24 col-lg-6 text-center text-lg-start px-3">
				<div class="mb-3">
					<h5>Ajuda e Suporte</h5>
					<ul class="list-unstyled mb-2">
						<!-- <li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="https://b2b.malwee.com.br/institucional/como-comprar"
								>Como Comprar</a
							>
						</li> -->
						<li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="{{customerAddresses.returns}}"
								>Devoluções</a
							>
						</li>
						<!-- <li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="https://b2b.malwee.com.br/b2b/notas-fiscais"
								>Notas Fiscais - XML e PDF</a
							>
						</li> -->
						<!-- <li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="https://b2b.malwee.com.br/b2b/portal-titulos"
								>2ª Via Boleto</a
							>
						</li> -->
						<li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="{{customerAddresses.callCenter}}"
								>Central de Atendimento</a
							>
						</li>
					</ul>
				</div>
				<div>
					<h5>Negócios</h5>
					<ul class="list-unstyled">
						<li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="{{customerAddresses.network}}"
								>Rede Aqui Tem Malwee</a
							>
						</li>
						<li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="{{customerAddresses.kidsFranchise}}"
								>Franquia Malwee Kids</a
							>
						</li>
						<li>
							<a
								class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
								href="{{customerAddresses.retailerServices}}"
								>Serviços Para Lojistas</a
							>
						</li>
					</ul>
				</div>
			</div>
			<div
				class="col-24 col-md-24 col-lg-6 separator text-center pe-lg-5 px-3 ps-lg-3 p text-lg-start"
			>
				<h5>LGPD</h5>
				<ul class="list-unstyled mb-2">
					<li>
						<a
							class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
							href="{{customerAddresses.privacyPolicy}}"
							>Política de Privacidade</a
						>
					</li>
					<li>
						<a
							class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
							href="{{customerAddresses.cookiesPolicy}}"
							>Política de Cookies</a
						>
					</li>
					<li>
						<a
							class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
							href="{{customerAddresses.termsOfUse}}"
							>Política de Termos de Uso</a
						>
					</li>
					<li>
						<a
							class="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-100-hover"
							href="{{customerAddresses.privacyAndTermsOfUse}}"
							>Política de Privacidade e termos de uso</a
						>
					</li>
				</ul>
			</div>
			<div class="col-24 col-md-24 p-0 col-lg-1 border-start border-light"></div>
			<div class="col-24 col-md-24 col-lg-auto p-0 border-bottom border-light"></div>
			<div class="col-24 col-md-24 col-lg-5 text-center text-lg-start ps-lg-5 ps-3 pe-3">
				<div class="mb-3">
					<h5>Conecte-se</h5>
					<div
						class="d-flex justify-content-lg-start gap-2 mb-3 justify-content-center text-lg-start text-white"
					>
						<a href="{{customerAddresses.socialMedias.facebook}}" target="_blank" class="text-white fs-4"
							><i class="bi bi-facebook"></i
						></a>
						<a href="{{customerAddresses.socialMedias.instagram}}" target="_blank" class="text-white fs-4"
							><i class="bi bi-instagram"></i
						></a>
						<a
							href="{{customerAddresses.socialMedias.youtube}}"
							target="_blank"
							class="text-white fs-4"
							><i class="bi bi-youtube"></i
						></a>
						<a
							href="{{customerAddresses.socialMedias.linkedIn}}"
							target="_blank"
							class="text-white fs-4"
							><i class="bi bi-linkedin"></i
						></a>
					</div>
				</div>
				<h5>Entre em Contato</h5>
				<p>{{customerAddresses.streetAddress}}<br /> {{customerAddresses.zipCode}} <br />{{customerAddresses.city}} - {{customerAddresses.state}}</p>
				<div>
					<i class="social-icon bi bi-telephone"></i>
					{{customerAddresses.telephone}}
				</div>
				<div>
					<i class="social-icon bi bi-whatsapp"></i>
					{{customerAddresses.telephoneWhatsapp}}
				</div>
				<div>
					<i class="social-icon bi bi-envelope"></i>
					{{customerAddresses.email}}
				</div>
			</div>
		</div>
	</div>
	<div class="text-center py-2 bg-light">
		<div class="d-flex align-items-center justify-content-center">
			<img src="./assets/svg/logo-geo.svg" height="25" alt="GEOvendas" />
		</div>
	</div>
</div>
