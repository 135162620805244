<h3>Informações da nota fiscal de devolução do cliente *</h3>
<p>Adicione os dados das notas fiscais para devolução</p>

<div class="overflow-auto">
	<table class="table table-bordered table-striped" aria-describedby="tabela-notas-fiscais-a-devolver">
		<thead>
			<tr>
				<th class="col-1" scope="col" *ngIf="isActive">Ações</th>
				<th class="col-3 text-center" scope="col">Nota Fiscal</th>
				<th class="col-2 text-center" scope="col">Série</th>
				<th class="col-3 text-center" scope="col">Data emissão</th>
				<th class="col-14 text-center" scope="col">Anexo</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let invoice of invoiceList">
				<tr>
					<td *ngIf="isActive">
						<div class="d-flex flex-column align-items-center justify-content-center gap-2">
							<button type="button" class="btn btn-secondary" (click)="editInvoice(invoice)" [disabled]="isReadonly">
								<i class="bi bi-pencil"></i>
							</button>
							<button type="button" class="btn btn-danger" (click)="removeInvoice(invoice)" *ngIf="isAddOrRemoveEnable">
								<i class="bi bi-trash"></i>
							</button>
						</div>
					</td>
					<td class="text-center">
						{{ createLabel(invoice, SalesReturnFieldIdType.InvoiceNumber) }}
					</td>
					<td class="text-center">
						{{ createLabel(invoice, SalesReturnFieldIdType.InvoiceSeries) }}
					</td>
					<td class="text-center">
						{{ createLabel(invoice, SalesReturnFieldIdType.InvoiceIssueDate) }}
					</td>
					<td class="text-center">
						{{ createLabel(invoice, SalesReturnFieldIdType.InvoiceAttachment) }}
					</td>
				</tr>
			</ng-container>
			<tr *ngIf="!hasInvoices">
				<td colspan="10">Nenhuma nota fiscal para devolver</td>
			</tr>
			<tr></tr>
		</tbody>
	</table>
</div>

<button type="button" class="btn btn-secondary" (click)="addInvoice()" *ngIf="isAddOrRemoveEnable">Adicionar nota fiscal</button>

<ng-template #invoiceModal let-modal>
	<form [formGroup]="invoiceForm" (ngSubmit)="saveInvoice()" novalidate>
		<div class="modal-header">
			<h4 class="modal-title" id="modal-basic-title">Nota fiscal a devolver</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
		</div>
		<div class="modal-body">
			<div class="row g-3 needs-validation">
				<div class="col-24">
					<label class="mb-2 text-muted" for="number">Número *</label>
					<input id="number" type="number" class="form-control" formControlName="number" [ngClass]="{ 'is-invalid': submitted && f.number.errors }" />
					<div class="invalid-feedback">Este campo é obrigatório (apenas números)</div>
				</div>

				<div class="col-24">
					<label class="mb-2 text-muted" for="serie">Série *</label>
					<input id="serie" type="number" class="form-control" formControlName="serie" [ngClass]="{ 'is-invalid': submitted && f.serie.errors }" />
					<div class="invalid-feedback">Este campo é obrigatório (apenas números)</div>
				</div>

				<div class="col-24">
					<label class="mb-2 text-muted" for="issueDate">Data de emissão *</label>
					<input
						ngbDatepicker
						id="issueDate"
						name="issueDate"
						#dpIssueDate="ngbDatepicker"
						type="issueDate"
						class="form-control"
						(click)="dpIssueDate.toggle()"
						placeholder="dd/mm/aaaa"
						formControlName="issueDate"
						[ngClass]="{ 'is-invalid': submitted && f.issueDate.errors }" />
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>
				<div class="col-24">
					<label for="attachment" class="form-label">Cópia da nota fiscal *</label>
					<div class="d-flex gap-2">
						<input id="attachment" type="file" type="file" accept=".pdf, .jpg, .jpeg, .png" class="d-none" (change)="onAddAttachment($event)" />
						<input
							type="text"
							class="form-control"
							[value]="invoiceForm.value.attachment?.nome"
							[ngClass]="{ 'is-invalid border-danger': submitted && f.attachment.errors }"
							[disabled]="true" />
						<label for="attachment" class="btn btn-secondary">Carregar</label>
						<button class="btn btn-danger" (click)="removeAttachment()" *ngIf="invoiceForm.value.attachment?.nome">Remover</button>
					</div>
					<div class="invalid-feedback d-inline" *ngIf="submitted && f.attachment.errors">Este campo é obrigatório</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
			<button type="submit" class="btn btn-primary">Salvar</button>
		</div>
	</form>
</ng-template>

<ng-template #maxItemCount let-modal>
	<div class="modal-header bg-warning">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">Nota Fiscal já anexada. Para uma segunda solicitação abra um novo processo.</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.dismiss()">Entendido</button>
	</div>
</ng-template>