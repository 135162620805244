import { Injectable } from '@angular/core';

import {
	SALES_TEAM_COOKIE_NAME,
	SALES_TEAM_LIST_COOKIE_NAME,
	CUSTOMER_SALES_TEAM_COOKIE_NAME,
	CUSTOMER_SALES_TEAM_LIST_COOKIE_NAME,
} from '@lib-core/constants';

import { SalesTeamModel } from '@lib-core/models';
import { BaseListStore } from '@lib-core/stores/base-list.store';

@Injectable({
	providedIn: 'root',
})
export class SalesTeamStore extends BaseListStore<SalesTeamModel> {
	private customerSalesTeamList: BaseListStore<SalesTeamModel>;

	constructor() {
		super(SALES_TEAM_COOKIE_NAME, SALES_TEAM_LIST_COOKIE_NAME);
		this.customerSalesTeamList = new BaseListStore<SalesTeamModel>(CUSTOMER_SALES_TEAM_COOKIE_NAME, CUSTOMER_SALES_TEAM_LIST_COOKIE_NAME);
	}

	override getList(): SalesTeamModel[] {
		if (this.isCustomerSalesTeamInUse) {
			return this.customerSalesTeamList.getList();
		}

		return super.getList();
	}

	override get(): SalesTeamModel {
		if (this.isCustomerSalesTeamInUse) {
			return this.customerSalesTeamList.get();
		}

		return super.get();
	}

	override set(data: SalesTeamModel): void {
		if (this.isCustomerSalesTeamInUse) {
			this.customerSalesTeamList.set(data);
			return;
		}

		super.set(data);
	}

	setCustomerSalesTeamList(values: SalesTeamModel[]) {
		this.customerSalesTeamList.setList(values);
	}

	clearCustomerSalesTeamList() {
		this.customerSalesTeamList.clearList();
	}

	get isCustomerSalesTeamInUse(): boolean {
		return !!this.customerSalesTeamList?.getList()?.length;
	}
}
