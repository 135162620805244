<shared-page-title>
	<ng-template #right>
		<a type="button" class="btn btn-primary" [routerLink]="accountReturnRoutes.upToFive.new.url">Nova solicitação</a>
	</ng-template>
</shared-page-title>

<table datatable *ngIf="isLoaded" [dtOptions]="dtOptions" aria-describedby="tabela-devoluções" class="table table-striped w-100">
	<thead>
		<tr>
			<th class="col-3 text-center" scope="col">Código</th>
			<th class="col-13 text-center" scope="col">Etapa</th>
			<th class="col-6 text-center" scope="col">Data movimentação</th>
			<th class="col-2 text-center" scope="col">Ações</th>
		</tr>
	</thead>
	<tbody class="table-group-divider">
		@for (item of salesReturnList; track item.id) {
		<tr class="align-middle">
			<td class="text-center">{{ item.id }}</td>
			<td>{{ item.nomeEtapa }}</td>
			<td class="text-center">
				{{ item.dataMovimentacaoEtapa | shortDate }}
			</td>
			<td class="px-3 text-center">
				<div [ngbTooltip]="getTooltip(item)">
					<a
						[class.disabled]="!shouldShowAction(item)"
						class="btn btn-outline-{{ getActionClass(item) }} w-100"
						[routerLink]="[accountReturnRoutes.upToFive.edit.url, item.id]">
						<i class="bi bi-{{ getActionIcon(item) }} d-inline d-lg-none"></i>
						<span class="d-none d-lg-inline">{{ getActionLabel(item) }}</span>
					</a>
				</div>
			</td>
		</tr>
		}
	</tbody>
</table>
