import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { NavbarComponent } from '@lib-shared/components';
import { ACCOUNT_ROUTE_LIST } from '@proj-customer/app/modules/account/account.routes';
import { AccountMenuComponent } from '@lib-shared/modules/account/account-menu/account-menu.component';

@Component({
	standalone: true,
	imports: [RouterOutlet, NavbarComponent, AccountMenuComponent],
	selector: 'customer-account-wrapper',
	templateUrl: './account-wrapper.component.html',
})
export class AccountWrapperComponent {
	accountRoutes = ACCOUNT_ROUTE_LIST;
}
