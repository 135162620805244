<shared-page-title />

<ul ngbNav #nav="ngbNav" class="nav-tabs">
	<li [ngbNavItem]="1">
		<button ngbNavLink>Solicitação</button>
		<ng-template ngbNavContent>
			<shared-account-sales-return-form [fluxo]="fluxo" [etapa]="etapa" [isActive]="true" />
		</ng-template>
	</li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
