import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet, NavigationEnd, NavigationError, NavigationStart, NavigationCancel } from '@angular/router';

import { AppModeStore } from '@lib-core/stores';
import { AppModeEnum } from '@proj-customer/app/enums/app-mode.enum';
import { AppLoaderService } from '@lib-core/services';
import { FooterComponent } from '@lib-shared/components';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppLoaderComponent, ToastsContainer } from '@lib-core/components';

@Component({
	standalone: true,
	selector: '[app-root]',
	imports: [RouterOutlet, FooterComponent, AppLoaderComponent, ToastsContainer],
	providers: [NgbTooltipConfig],
	templateUrl: './app.component.html',
	styles: [
		`
			:host {
				height: 100vh;
				display: flex;
				overflow-x: hidden;
				flex-direction: column;
				main {
					flex: 1;
				}
			}
		`,
	],
})
export class AppComponent implements OnInit {
	constructor(private router: Router, private appModeStore: AppModeStore, private appLoaderService: AppLoaderService, private ngbTooltipConfig: NgbTooltipConfig) {
		this.appModeStore.set(AppModeEnum.PortalCustomer);
		this.tooltipConfig();
	}

	ngOnInit(): void {
		this.router.events.subscribe(event => {
			switch (true) {
				case event instanceof NavigationStart:
					this.appLoaderService.start();
					break;
				case event instanceof NavigationEnd:
				case event instanceof NavigationError:
				case event instanceof NavigationCancel:
					this.appLoaderService.stop();
					break;
			}
		});
	}

	tooltipConfig(): void {
		this.ngbTooltipConfig.closeDelay = 0;
		this.ngbTooltipConfig.openDelay = 400;
	}
}
