<div *ngIf="isValid">
	<shared-page-title title="{{ 'Processo: ' + processo?.processo_id }}" />

	<ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="activeTab">
		<li [ngbNavItem]="requestStep" *ngIf="isValidTab(1)">
			<button ngbNavLink>Solicitação</button>
			<ng-template ngbNavContent>
				<shared-account-sales-return-form [fluxo]="fluxo" [etapa]="requestStep" [isActive]="isRequest && !processo.processo_id" [processo]="processo" />
			</ng-template>
		</li>
		<li [ngbNavItem]="proofOfDiscardStep" *ngIf="isValidTab(2) && processo.fluxo_id == SalesReturnType.UpToFive">
			<button ngbNavLink>Comprovação de descarte do produto</button>
			<ng-template ngbNavContent>
				<shared-account-sales-return-form [fluxo]="fluxo" [etapa]="proofOfDiscardStep" [isActive]="isProofOfDiscard" [processo]="processo" />
			</ng-template>
		</li>
		<li [ngbNavItem]="goodsReturnStep" *ngIf="isValidTab(2) && isDoneStepAndSixOrMore">
			<button ngbNavLink>Coleta de Mercadoria</button>
			<ng-template ngbNavContent>
				<shared-account-sales-return-form
					[fluxo]="fluxo"
					[etapa]="goodsReturnStep"
					[isActive]="false"
					[processo]="processo"
					[trackingCode]="trackingCode"
					[transportationType]="transportationType" />
			</ng-template>
		</li>
		<li [ngbNavItem]="doneStep" *ngIf="isValidTab(3)">
			<button ngbNavLink disabled>Encerrado</button>
			<ng-template ngbNavContent>
				<shared-account-sales-return-form
					[fluxo]="fluxo"
					[etapa]="doneStep"
					[isActive]="false"
					[processo]="processo"
					[trackingCode]="trackingCode"
					[transportationType]="transportationType" />
			</ng-template>
		</li>
	</ul>

	<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<div class="row justify-content-center text-center" *ngIf="isInvalid">
	<div class="col-4 mt-4">
		<i class="bi bi-bug text-primary" [style.font-size]="'5rem'"></i>
	</div>
	<div class="w-100"></div>
	<div class="col-12">
		<h3>A solicitação não foi encontrada</h3>
		<p>Caso tenha certeza que ela existe, entre em contato com nossa equipe</p>
	</div>
	<div class="w-100"></div>
	<div class="col-6 mb-3">
		<a class="btn btn-outline-secondary" [routerLink]="'/'">
			<i class="bi bi-arrow-left me-1"></i>
			<span>Voltar para minha conta</span>
		</a>
	</div>
</div>
